import MainSliderPic3 from "./img/Frame_135.png";
import MainSliderPic4 from "./img/Frame_136.png";
export const pictures = [
  {
    url: MainSliderPic3,
  },
  {
    url: MainSliderPic4,
  },
];
