import React from "react";
import "./PriceListComponent.css";

export const OfertaComponent = () => {
   return (
      <div className="OfertaComponent">
         <div className="OfertaComponent__inner">
            Прайс-лист не является публичной офертой
         </div>
      </div>
   );
};
